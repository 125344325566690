import * as React from "react";
import { Link } from "gatsby";
//import ArrowRight from "../../images/arrow-right.png"
//import Image from "../Image"
import PropTypes from "prop-types";
import Card from "./base";
//import { Button } from "../atoms/buttons"
import { Title, SubTitle } from "../atoms/titles";

const CategoryCard = ({
  title,
  subtitle,
  cta,
  image = false,
  className,
  children,
  ...props
}) => {
  //console.log(image)

  return (
    <Card className={className}>
      <Link className="" to={cta.url}>
        {image && (
          <img src={image.url} alt={image.alt} className=" lg:hidden" />
        )}
        <Title className="text-3xl lg:text-[40px] font-bold">{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </Link>
      <Link
        className="lg:button-primary underline lg:no-underline	 button-sm mb-4 lg:my-4"
        to={cta.url}
      >
        {cta.label}
      </Link>
      {children}

      {image && (
        <Link className="" to={cta.url}>
          <img src={image.url} alt={image.alt} className="hidden lg:block" />
        </Link>
      )}
    </Card>
  );
};

CategoryCard.defaultProps = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: "border py-9 px-8",
  cta: {
    label: PropTypes.string,
    url: PropTypes.string,
  },
  image: {
    url: PropTypes.string,
    alt: PropTypes.string,
  },
};

const CategoryCardExample = () => {
  return (
    <CategoryCard
      title="Bycle"
      subtitle="great one"
      image={{
        url: "https://picsum.photos/484/272?random=20",
        alt: "some thing",
      }}
      cta={{
        label: "learn more",
        url: "/",
      }}
    />
  );
};

export default CategoryCard;
export { CategoryCardExample };
