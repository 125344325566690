import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
//import SectionGrid2 from "../components/sections/grid/grid2";
import SectionBase from "../components/sections/base";
import Grid2 from "../components/grids/grid2";
import SectionGrid3 from "../components/sections/grid/grid3";
import HeroInner2 from "../components/sections/hero/inner2";
import { Title } from "../components/atoms/titles";
import SectionContent from "../components/sections/content";
import Tabs from "../components/tabs/tabs";
import { Html } from "../components/atoms/text";
import YouTube from "react-youtube";
import Agent from "../translations/agent";
import useTopCategories from "../hooks/useTopCategories";
import SectionCategories from "../components/sections/categories";

const IndexPage = ({ data: { node } }) => {
  //console.log(node)
  //console.log(node.relationships.section2)
  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1 data={node} />
      <Section2 data={node.relationships.section2} />
      <Section3 data={node} />
      <Section4 data={node} />
      <Section5 data={node} />
      <Section6 />
    </Layout>
  );
};

const Hero = ({ data }) => {
  const { title, subtitle } = data;
  const url = data.relationships.image.localFile.publicURL;

  const action1 = data.relationships.actions[0];

  let backgrounds = [];
  backgrounds = data.relationships.images.map((m) => m.localFile.publicURL);
  if (backgrounds.length === 0) {
    backgrounds.push(url);
  }

  return (
    <HeroInner2
      title={title}
      //style={{ backgroundImage: `url("${url}")` }}
      backgrounds={backgrounds}
      description={subtitle}
      action1={action1}
    />
  );
};

const Section1 = ({ data }) => {
  const {
    wheel_size,
  } = data;

  return (
    <SectionGrid3 className="text-dark lg:py-8 container ">
      <div className="text-center" />
      <div className="text-center">
        <div className="text-xl">{Agent.translate("wheel size")}</div>
        <Title className="text-2xl lg:text-[40px]">{wheel_size} </Title>
      </div>
      <div className="text-center" />
    </SectionGrid3>
  );
};

const Section2 = ({ data }) => {
  const html = data?.description[0]?.processed;
  const { title } = data;

  return (
    <SectionContent
      className="py-10 lg:py-20 text-center lg:text-right  "
      classNameContainer="container space-y-4"
      classNameTitle="text-4xl font-bold leading-9 "
      classNameBody="text-lg lg:text-xl"
      title={title}
      description={html}
    />
  );
};

const Section3 = ({ data }) => {
  return (
    <SectionContent
      className="py-12 bg-dark text-white"
      classNameTitle="text-2xl lg:text-4xl text-center font-light"
      title={data?.section_title}
    />
  );
};

const Section4 = ({ data }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const images = data.relationships.images.map((item, index) => ({
    src: item.localFile.publicURL,
    alt: data.images[index].alt,
  }));

  images.unshift({
    src: data.relationships.image.localFile.publicURL,
    alt: data.image.alt,
  });

  const itemsDesktop = images.map((item, index) => (
    <button
      onClick={() => setSelectedImageIndex(index)}
      key={index}
      className={
        "border " +
        (index === selectedImageIndex ? " border-dark" : " border-white")
      }
    >
      <img src={item.src} alt={item.alt} />
    </button>
  ));

  const itemsMobile = images.map((item, index) => (
    <div key={index}>
      <img src={item.src} alt={item.alt} />
    </div>
  ));

  return (
    <SectionBase
      className="py-12 mx-4 lg:mx-24"
      classNameGrid="gap-12 lg:gap-24"
    >
      <div className="flex flex-col lg:flex-row justify-around ">
        <div className="hidden lg:block lg:w-1/2">
          <img
            src={images[selectedImageIndex].src}
            alt={images[selectedImageIndex].alt}
          />
        </div>

        <div className="lg:w-1/3">
          <div className="hidden lg:block">
            <Grid2>{itemsDesktop}</Grid2>
          </div>

          <div className="lg:hidden">
            <Grid2>{itemsMobile}</Grid2>
          </div>
        </div>
      </div>
    </SectionBase>
  );
};

const Section5 = ({ data }) => {
  const url = data?.video?.video_id;
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <>
      <section className="my-12 mx-4 lg:mx-24  h-fit">
        <Tabs
          className=""
          classNameButtons="flex flex-row space-x-2 lg:space-x-10 space-x-reverse lg:space-x-reverse justify-center py-4"
          classNameButton="button-white text-base lg:text-xl font-bold bg-dark lg:bg-white text-white lg:text-dark"
          classNameButtonActive="button-primary text-base lg:text-xl font-bold"
        >
          <Tabs.Tab title="סרטון מוצר">
            <div className="h-[500px]">
              <YouTube
                videoId={url}
                opts={opts}
                className="p-1 h-full w-full "
                //onEnd={close}
                //onReady={this._onReady}
              />
            </div>
          </Tabs.Tab>

          <Tabs.Tab title="מאפיינים">
            <Html className="max-w-[900px] mx-auto p-8 ">
              {data?.properties?.processed}
            </Html>
          </Tabs.Tab>

        </Tabs>
      </section>
    </>
  );
};

const Section6 = () => {
  const items = useTopCategories();

  return <SectionCategories items={items} className="lg:mx-24 lg:pt-32" />;
};

export default IndexPage;

export const query = graphql`
  query product($id: String!) {
    node: nodeProduct(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      chassis_size: field_chassis_size
      wheel_size: field_wheel_size
      image: field_image {
        alt
      }
      images: field_images {
        alt
      }
      video: field_product_video {
        video_id
        input
      }
      properties: field_properties {
        processed
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        section2: field_paragraph_block {
          ...BlockContent
        }
        image: field_image {
          ...Image
        }
        images: field_images {
          ...Image
        }
      }
    }
  }
`;
